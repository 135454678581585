import { useReducer } from 'react';
import useLocalStorage from 'react-use/lib/useLocalStorage';

export function useLocalStorageReducer(
  key: string,
  reducer: any,
  initialState: any,
  initializer?: undefined
) {
  const [localStorageState, setLocalStorageState] = useLocalStorage(
    key,
    initialState
  );

  return useReducer(
    (state: any, action: any) => {
      const newState = reducer(state, action);
      setLocalStorageState(newState);
      return newState;
    },
    { ...localStorageState },
    initializer
  );
}
