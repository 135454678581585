import { createContext, Dispatch, FC, useMemo } from 'react';
import { useLocalStorageReducer } from '../../client/useLocalStorageReducer';
import { ActionType, cartReducer, initialState, StateType } from './reducers';

const STORAGE_KEY = 'cart2.5';

export const CartContext = createContext<{
  state: StateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const CartProvider: FC = ({ children }) => {
  const [state, dispatch] = useLocalStorageReducer(
    STORAGE_KEY,
    cartReducer,
    initialState
  );
  const contextValue = useMemo(() => ({ state, dispatch }), [state]);
  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
