import { useEffect, useState } from 'react';

type Experiment = {
  random?: boolean;
  name: string;
  date: string;
  variations: string[];
  fallback?: string;
};

const prefix = 'experiment:';
export const experiments: Experiment[] = [];

const createExperiment = (experiment: Experiment) => () => {
  experiments.push(experiment);

  const { random = true, name, date, variations, fallback } = experiment;

  if (!random) {
    const urlParams = new URLSearchParams(window.location.search);
    setUrlExperiments(urlParams.get('experiments'));
  }

  const key = `${prefix}${name}-${date}`;
  const value = window.localStorage.getItem(key);

  if (value && (variations.includes(value) || value === fallback)) {
    return value;
  }

  let selectedValue;
  if (random) {
    selectedValue = variations[Math.floor(Math.random() * variations.length)];
  } else {
    selectedValue = fallback!;
  }
  window.localStorage.setItem(key, selectedValue);
  return selectedValue;
};

export const getExperimentsData = () =>
  Object.keys(window.localStorage)
    .filter(
      (x) =>
        x.startsWith(prefix) &&
        experiments.find(({ name, date }) => `${prefix}${name}-${date}` === x)
    )
    .reduce(
      (a, key) => ({
        ...a,
        [key.replace(prefix, '')]: window.localStorage.getItem(key),
      }),
      {}
    );

export const setUrlExperiments = (queryParam?: string | null) => {
  if (!queryParam) {
    return;
  }

  const [name, value] = queryParam.split(':');

  const key = `${prefix}${name}`;
  const exists = window.localStorage.getItem(key);

  // override
  if (value) {
    // if (!exists && value) { // not override
    window.localStorage.setItem(key, value);
  }
};

export function useExperiment(getter: any, initialState: any) {
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    if (process.browser) {
      setValue(getter());
    }
  }, []);

  return value;
}

// export const getChoosePageBannerVisibility = createExperiment({
//   name: 'choose-page-banner',
//   date: '2020-12-02',
//   variations: ['visible', 'hidden'],
// });

// export const getSmallBoxId = createExperiment({
//   name: 'box-rekrut-with-and-without-shipping',
//   date: '2020-12-02',
//   variations: ['rekrut-noshipping', 'rekrut-shipping690'],
// });

// export const getSmallBoxId = createExperiment({
//   name: 'box-rekrut-shipping-price',
//   date: '2020-12-13',
//   variations: ['rekrut-shipping1290', 'rekrut-shipping690'],
// });

// export const getShippingPrice = createExperiment({
//   name: 'shipping-price',
//   date: '2020-12-15',
//   variations: ['690', '990', '1290'],
// });

// export const getPricingGroup = createExperiment({
//   random: false,
//   name: 'group',
//   date: '2020-12-20',
//   variations: ['A', 'B'],
//   fallback: 'C',
// });

// export const getPricingGroup = createExperiment({
//   name: 'group',
//   date: '2021-01-06',
//   variations: ['A', 'B'],
// });

// export const getPricingGroup = createExperiment({
//   name: 'group',
//   date: '2021-01-11',
//   variations: ['A', 'B'],
// });

// export const getPricingGroup = createExperiment({
//   name: 'group',
//   date: '2021-02-17',
//   variations: ['A', 'B', 'C'],
// });

// export const getGlobalDelivery = createExperiment({
//   random: false,
//   name: 'global',
//   date: '2021-01-15',
//   variations: ['yes'],
//   fallback: 'no',
// });

// export const getCheckoutPageVersion = createExperiment({
//   name: 'checkout',
//   date: '2021-01-20',
//   variations: ['v1', 'v2'],
// });

// export const getInstaReviewsVisibility = createExperiment({
//   name: 'insta-reviews',
//   date: '2021-02-22',
//   variations: ['visible', 'hidden'],
// });

// export const getFooterVersion = createExperiment({
//   name: 'footer',
//   date: '2021-02-25',
//   variations: ['v1', 'v2'],
// });

// export const getDeliveryDateVersion = createExperiment({
//   name: 'delivery-date',
//   date: '2021-02-25',
//   variations: ['v1', 'v2', 'v3'],
// });

// export const getCheckoutVersion = createExperiment({
//   name: 'checkout-flow',
//   date: '2021-04-23',
//   variations: ['v1', 'v1.1'],
// });

export const getCheckoutVersion2 = () => 'v3';
// createExperiment({
//   name: 'checkout-flow-2',
//   date: '2021-04-27',
//   variations: ['v1.1', 'v3'],
// });

export const getBlikVersion = () => 'v2';
// createExperiment({
//   name: 'blik',
//   date: '2021-05-01',
//   variations: ['v1', 'v2'],
// });

// export const getPaymentVersion = createExperiment({
//   name: 'payment',
//   date: '2021-04-16',
//   variations: ['v1', 'v2'],
// });

// export const getPaymentFlow = createExperiment({
//   name: 'payment-flow',
//   date: '2021-04-21',
//   variations: ['v1', 'v2'],
// });

export const getCheckoutHeaderVersion = () => 'v2';
// createExperiment({
//   name: 'checkout-header',
//   date: '2021-05-06',
//   variations: ['v1', 'v2'],
// });

export const getAddressVersion = () => 'v2';
// createExperiment({
//   name: 'address',
//   date: '2021-05-10',
//   variations: ['v1', 'v2'],
// });

export const getDeliverySectionVersion = () => 'v2';
// createExperiment({
//   name: 'delivery-section',
//   date: '2021-06-12',
//   variations: ['v1', 'v2'],
// });

// export const getUpsellVersion = createExperiment({
//   name: 'upsell',
//   date: '2021-06-25',
//   variations: ['v1', 'v2'],
// });
export const getUpsellVersion = () => 'v1';

export const getSmallBoxVersion = () => 'v2';
// export const getSmallBoxVersion = createExperiment({
//   name: 'small-box-present',
//   date: '2021-07-05',
//   variations: ['v1', 'v2'],
// });

export const getHeaderVersion = () => 'v2';
// createExperiment({
//   name: 'new-header',
//   date: '2021-07-13',
//   variations: ['v1', 'v2'],
// });

export const getSmallBoxMinOrderFlow = () => 'v2';
//   createExperiment({
//   name: 'small-box-min-order',
//   date: '2021-07-18',
//   variations: ['v1', 'v2'],
// });

export const getShowDeliveryLabel = () => 'yes';
//   createExperiment({
//   name: 'delivery-label',
//   date: '2021-07-15',
//   variations: ['no', 'yes'],
// });

export const getProductPriceVersion = () => 'v2';
// createExperiment({
//   name: 'upsell-price',
//   date: '2021-07-15',
//   variations: ['v1', 'v2'],
// });

export const getGiftProgressShow = () => 'yes';
// createExperiment({
//   name: 'gift-progress-bar',
//   date: '2021-07-20',
//   variations: ['no', 'yes'],
// });

export const getBigBoxNameVersion = () => 'v1';
// createExperiment({
//   name: 'big-box-name',
//   date: '2021-07-15',
//   variations: ['v1', 'v2', 'v3', 'v4'],
// });

export const getSplitAddressVersion = () => 'v2';

export const getHeadBgVersion = () => 'v1';
// createExperiment({
//   name: 'pre-header-bg',
//   date: '2021-07-31',
//   variations: ['v1', 'v2'],
// });

export const getOrderUnder80Flow = () => 'v2';
// createExperiment({
//   name: 'order-under-80',
//   date: '2021-08-05',
//   variations: ['v1', 'v2'],
// });

export const getLower80Lbl = () => 'v2';
// createExperiment({
//   name: 'lower-80-lbl',
//   date: '2021-09-02',
//   variations: ['v1', 'v2', 'v3'],
// });

export const getLower100Lbl = () => 'v3';
//   createExperiment({
//   name: 'lower-100-lbl',
//   date: '2021-09-07',
//   variations: ['v1', 'v2', 'v3', 'v4'],
// });

export const getLower160Lbl = () => 'v6';
//   createExperiment({
//   name: 'lower-160-lbl',
//   date: '2021-09-22',
//   // variations: ['v1', 'v2', 'v3'],
//   variations: ['v3', 'v6'],
// });

export const getGiftStyle = () => 'v2';
//   createExperiment({
//   name: 'gift-block-style',
//   date: '2021-11-03',
//   variations: ['v1', 'v2'],
// });

export const getProgressBarStyle = createExperiment({
  name: 'progress-bar-style',
  date: '2021-11-18',
  variations: ['v1', 'v2', 'v3', 'v4'],
});
