import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'shared/auth/AuthContext';
import { useApollo } from 'shared/auth/apollo';
import Head from 'next/head';
import { useAnalytics } from '../client/useAnalytics';
import { useExperiments } from '../client/useExperiments';
import { CartProvider } from '../components/cart/context';
// import { CustomerChat } from '../components/facebook/CustomerChat';
import '../polyfills';
import '../styles/tailwind.css';
import '../styles/vk.css';

export default function App({ Component, pageProps, err }) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  useExperiments();
  useAnalytics();

  return (
    <>
      <Head>
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          // content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <ApolloProvider client={apolloClient}>
        <AuthProvider initialIdentity={pageProps.initialIdentity}>
          <CartProvider>
            <>
              {/* Facebook Customer Chat */}
              {/* <CustomerChat
                locale="pl_PL"
                appId={process.env.NEXT_PUBLIC_FB_APP_ID}
                pageId={process.env.NEXT_PUBLIC_FB_PAGE_ID}
                themeColor="#FAAF00"
                loggedInGreeting="Witaj 🤚, w czym mogę Ci pomóc?"
                loggedOutGreeting="Witaj 🤚, w czym mogę Ci pomóc?"
              /> */}

              {/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
              <Component {...pageProps} err={err} />
            </>
          </CartProvider>
        </AuthProvider>
      </ApolloProvider>
    </>
  );
}
