export type CouponType = {
  id: string;
  name: string;
  amountOff: number | null;
  percentOff: number | null;
};

export type CartItemType = {
  // TODO
  id: number;
  name: string;
  price: number;
  priceId: number;
  quantity: number;
  // productQuantity: number;
  type: string;
  sku: string;
  shippingGrossPrice: number;
  metadata: object;
  // descriptionHtml: string;
};

export type IntervalType = {
  interval: string | null;
  intervalCount: number | null;
};

export type ShippingBatchType = {
  id: number;
  // unitShippingCost: number;
  // unitShippingAmount: number;
  shippingTimeRange?: string;
  estimatedShippingDays?: number;
  metadata: object;
};

export type StateType = {
  isCartOpened: boolean;
  cart: {
    [id: string]: CartItemType;
  };
  appliedCoupons: CouponType[];
  // totalPrice: 0,
  itemsQuantity: number;
  deliveryDate: string;
  deliveryBatch: ShippingBatchType | null;
  deliveryPostalCode: string | null;
  subscriptionInterval: IntervalType;
  checkoutId: string | null;
};

export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const ADD_COUPON = 'ADD_COUPON';
export const REMOVE_COUPON = 'REMOVE_COUPON';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const SET_ITEM_QUANTITY = 'SET_ITEM_QUANTITY';
export const INCREMENT_ITEM = 'INCREMENT_ITEM';
export const DECREMENT_ITEM = 'DECREMENT_ITEM';
export const CLEAR_CART = 'CLEAR_CART';
export const TOGGLE_CART = 'TOGGLE_CART';
export const SHOW_CART = 'SHOW_CART';
export const HIDE_CART = 'HIDE_CART';
export const SET_INTERVAL = 'SET_INTERVAL';
export const SET_CHECKOUT_ID = 'SET_CHECKOUT_ID';
export const SET_DELIVERY_BATCH = 'SET_DELIVERY_BATCH';
export const SET_DELIVERY_POSTAL_CODE = 'SET_DELIVERY_POSTAL_CODE';

export type ActionType =
  | { type: typeof SET_DELIVERY_DATE; deliveryDate: string }
  | { type: typeof SET_DELIVERY_BATCH; deliveryBatch: ShippingBatchType }
  | { type: typeof SET_DELIVERY_POSTAL_CODE; deliveryPostalCode: string }
  | { type: typeof ADD_COUPON; coupon: CouponType }
  | { type: typeof REMOVE_COUPON; couponId: string }
  | {
      type: typeof ADD_ITEM;
      product: Omit<CartItemType, 'quantity'>;
      quantity: number;
    }
  | { type: typeof REMOVE_ITEM; id: number }
  | { type: typeof SET_ITEM_QUANTITY; id: number; quantity: number }
  | { type: typeof INCREMENT_ITEM; id: number; quantity: number }
  | { type: typeof DECREMENT_ITEM; id: number; quantity: number }
  | { type: typeof CLEAR_CART }
  | { type: typeof TOGGLE_CART }
  | { type: typeof SHOW_CART }
  | { type: typeof HIDE_CART }
  | { type: typeof SET_INTERVAL; subscriptionInterval: IntervalType }
  | { type: typeof SET_CHECKOUT_ID; checkoutId: string | null };

export const initialState: StateType = {
  isCartOpened: false,
  cart: {},
  appliedCoupons: [],
  // totalPrice: 0,
  itemsQuantity: 0,
  deliveryDate: '',
  deliveryBatch: null,
  deliveryPostalCode: null,
  subscriptionInterval: {
    interval: null,
    intervalCount: null,
  },
  checkoutId: null,
};

export function cartReducer(state: StateType, action: ActionType) {
  function createEntry(
    product: Omit<CartItemType, 'quantity'>,
    quantity: number
  ) {
    const entry = {
      ...product,
      quantity,
      // TODO rework
      // @ts-ignore
      get value() {
        return this.price * this.quantity;
      },
    };

    return {
      ...state,
      cart: {
        ...state.cart,
        [entry.id]: entry,
      },
      // totalPrice: state.totalPrice + product.price * quantity,
      itemsQuantity: state.itemsQuantity + quantity,
    };
  }

  function updateEntry(id: number, quantity: number) {
    const cart = { ...state.cart };
    const entry = cart[id];
    if (entry.quantity + quantity <= 0) return removeEntry(id);

    cart[id] = {
      ...entry,
      quantity: entry.quantity + quantity,
      // TODO rework
      get value() {
        return this.price * this.quantity;
      },
    };

    return {
      ...state,
      cart,
      // totalPrice: state.totalPrice + entry.price * quantity,
      itemsQuantity: state.itemsQuantity + quantity,
    };
  }

  function removeEntry(id: number) {
    const cart = { ...state.cart };
    // const totalPrice = state.totalPrice - cart[id].value;
    const itemsQuantity = state.itemsQuantity - cart[id].quantity;
    delete cart[id];

    return { ...state, cart, itemsQuantity };
  }

  function updateQuantity(id: number, quantity: number) {
    const entry = state.cart[id];
    return updateEntry(id, quantity - entry.quantity);
  }

  switch (action.type) {
    case TOGGLE_CART:
      return {
        ...state,
        isCartOpened: !state.isCartOpened,
      };

    case SHOW_CART:
      return {
        ...state,
        isCartOpened: true,
      };

    case HIDE_CART:
      return {
        ...state,
        isCartOpened: false,
      };

    case ADD_ITEM:
      if (action.quantity <= 0) break;
      if (action.product.id in state.cart)
        return updateEntry(action.product.id, action.quantity);
      return createEntry(action.product, action.quantity);

    case INCREMENT_ITEM:
      if (action.quantity <= 0) break;
      if (action.id in state.cart)
        return updateEntry(action.id, action.quantity);
      break;

    case DECREMENT_ITEM:
      if (action.quantity <= 0) break;
      if (action.id in state.cart)
        return updateEntry(action.id, -action.quantity);
      break;

    case SET_ITEM_QUANTITY:
      if (action.quantity < 0) break;
      if (action.id in state.cart)
        return updateQuantity(action.id, action.quantity);
      break;

    case REMOVE_ITEM:
      if (action.id in state.cart) return removeEntry(action.id);
      break;

    case CLEAR_CART:
      return { ...initialState, checkoutId: state.checkoutId };

    case SET_DELIVERY_DATE:
      return {
        ...state,
        deliveryDate: action.deliveryDate,
      };

    case SET_DELIVERY_BATCH:
      return {
        ...state,
        deliveryBatch: action.deliveryBatch,
      };

    case SET_DELIVERY_POSTAL_CODE:
      return {
        ...state,
        deliveryPostalCode: action.deliveryPostalCode,
      };

    case ADD_COUPON:
      // if coupon exists
      if (state.appliedCoupons.some((x) => x.id === action.coupon.id)) {
        return state;
      }

      return {
        ...state,
        appliedCoupons: [...state.appliedCoupons, action.coupon],
      };

    case REMOVE_COUPON:
      return {
        ...state,
        appliedCoupons: state.appliedCoupons.filter(
          (x) => x.id !== action.couponId
        ),
      };

    case SET_INTERVAL:
      return {
        ...state,
        subscriptionInterval: action.subscriptionInterval,
      };

    case SET_CHECKOUT_ID:
      return {
        ...state,
        checkoutId: action.checkoutId,
      };

    default:
      return state;
  }
}
