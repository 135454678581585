import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getExperimentsData } from './experiments';

const LANDING_SITE_KEY = 'wwls2';
const REFERRING_SITE_KEY = 'wwrs2';
const UTM_KEY = 'wwutm2';
const EXPIRES_IN_DAYS = 7;

export function useAnalytics() {
  const router = useRouter();

  useEffect(() => {
    if (process.browser) {
      const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,

        // Our way, as Ad Block removes utm params from links
        sr,
        md,
        cp,
        tm,
        ct,

        as,
        pl,
      } = router.query;

      if (!Cookies.get(LANDING_SITE_KEY) && !Cookies.get(REFERRING_SITE_KEY)) {
        const wwls = document.location.href; // landingSite
        const wwrs = document.referrer; // referringSite

        Cookies.set(LANDING_SITE_KEY, wwls, {
          expires: EXPIRES_IN_DAYS,
        });
        Cookies.set(REFERRING_SITE_KEY, wwrs, {
          expires: EXPIRES_IN_DAYS,
        });
      }

      if (utm_source && utm_medium && utm_campaign) {
        Cookies.set(
          UTM_KEY,
          JSON.stringify({
            source: utm_source,
            medium: utm_medium,
            campaign: utm_campaign,
            term: utm_term,
            content: utm_content,
            adset: as,
            placement: pl,
          }),
          {
            expires: EXPIRES_IN_DAYS,
          }
        );
      } else if (sr && md && cp) {
        // Our way, as Ad Block removes utm params from links
        Cookies.set(
          UTM_KEY,
          JSON.stringify({
            source: sr,
            medium: md,
            campaign: cp,
            term: tm,
            content: ct,
            adset: as,
            placement: pl,
          }),
          {
            expires: EXPIRES_IN_DAYS,
          }
        );
      }
    }
  }, [router.query]);
}

export const getBrowserInfo = () => {
  if (process.browser) {
    return {
      acceptLanguage: navigator.language,
      browserHeight: window.innerHeight,
      browserWidth: window.innerWidth,
      userAgent: navigator.userAgent,
    };
  }
};

export const getMetadata = () => {
  return {
    landingSite: Cookies.get(LANDING_SITE_KEY),
    referringSite: Cookies.get(REFERRING_SITE_KEY),
    utm: getUTM(),
    experiments: getExperimentsData(),
    ...getBrowserInfo(),
  };
};

export const clearUrlMetadataInStorage = () => {
  Cookies.remove(LANDING_SITE_KEY);
  Cookies.remove(REFERRING_SITE_KEY);
  Cookies.remove(UTM_KEY);
};

function getUTM() {
  try {
    const value = Cookies.get(UTM_KEY);
    return value ? JSON.parse(value) : undefined;
  } catch {
    return undefined;
  }
}
