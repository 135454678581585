import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { setUrlExperiments } from './experiments';

export function useExperiments() {
  const router = useRouter();

  useEffect(() => {
    if (process.browser) {
      setUrlExperiments(<string>router.query?.experiments);
    }
  }, [router.query]);
}
